import {
  CustomizationUnit,
  applyCustomization,
} from 'common/repositories/customization';
import { MaybeText } from 'components/common/maybe';
import Separator from 'components/common/separator';
import Button, { ButtonProps } from 'components/elements/button';
import { TextProps } from 'components/elements/text';
import BackgroundImage from 'components/widgets/image/background';
import { StaticImageData } from 'next/image';
import React, { useId } from 'react';
import structuralStyles from 'styles/layout.css';

import { BannerSectionStyles } from './styles.css';

interface BannerSectionCustomization {
  root?: CustomizationUnit<React.ComponentProps<'div'>>;
  container?: CustomizationUnit<React.ComponentProps<'div'>>;
  title?: CustomizationUnit<TextProps> & {
    rightComponent?: React.ReactNode;
  };
  description?: CustomizationUnit<TextProps>;
  image?: CustomizationUnit<React.ComponentProps<typeof BackgroundImage>>;
  extendedComponent?: React.ReactNode;
}
interface LabelledSectionProps {
  label: string | React.ReactNode;
  description?: string | React.ReactNode;
  children?: React.ReactNode;

  padBody?: boolean;
  separator?: number;
  TitleEnd?: React.ReactNode;

  customization?: {
    root?: CustomizationUnit<React.ComponentProps<'section'>>;
    header?: {
      root?: CustomizationUnit<React.ComponentProps<'header'>>;
    };
  };
}

interface BannerSectionProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  image?: StaticImageData | string;
  mainActionProps?: ButtonProps;
  customization?: BannerSectionCustomization;
}

export function LabelledSection(props: LabelledSectionProps) {
  const {
    label,
    description,
    children,
    padBody,
    TitleEnd,
    customization,
    separator = 16,
  } = props;
  const id = `kurosim--sections--header--${useId()}`;
  return (
    <section
      aria-labelledby={id}
      {...applyCustomization(
        {
          className: structuralStyles.padding({ top: 24 }),
        },
        [customization?.root],
      )}
    >
      <header
        {...applyCustomization(
          {
            className: structuralStyles.padding({
              horizontal: 16,
            }),
          },
          [customization?.header?.root],
        )}
      >
        <div className={structuralStyles.flexbox({ justify: 'between' })}>
          <MaybeText textVariant="body1Semibold" id={id}>
            {label}
          </MaybeText>
          {TitleEnd}
        </div>
        <MaybeText textVariant="body2Regular">{description}</MaybeText>
      </header>
      {separator ? <Separator gap={separator} /> : null}
      {padBody ? (
        <div className={structuralStyles.padding({ horizontal: 16 })}>
          {children}
        </div>
      ) : (
        children
      )}
    </section>
  );
}

export function BannerSection(props: BannerSectionProps) {
  const { image, description, title, mainActionProps, customization } = props;
  const id = `kurosim--banners--${useId()}`;
  return (
    <section
      {...applyCustomization({ className: BannerSectionStyles.padding }, [
        customization?.root,
      ])}
      aria-labelledby={id}
    >
      <div
        {...applyCustomization({ className: BannerSectionStyles.container }, [
          customization?.root,
        ])}
      >
        {image && (
          <BackgroundImage
            {...applyCustomization(
              {
                src: image,
                alt:
                  typeof title === 'string'
                    ? title
                    : typeof description === 'string'
                      ? description
                      : 'Banner Image',
              },
              [customization?.image],
            )}
          />
        )}
        <MaybeText
          textVariant="h3"
          id={id}
          {...applyCustomization({}, [customization?.title])}
        >
          {title}
        </MaybeText>
        {description && (
          <MaybeText
            textVariant="body2Regular"
            {...applyCustomization({}, [customization?.description])}
          >
            {description}
          </MaybeText>
        )}
        <Separator gap={16} />
        {mainActionProps && <Button fullWidth {...mainActionProps} />}
      </div>
    </section>
  );
}
