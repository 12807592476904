import { SimModel } from 'api-hooks/sim/model';
import React from 'react';

export const EsimContext = React.createContext<{
  sim: SimModel | undefined;
}>({
  sim: undefined,
});

export function EsimProvider(props: {
  sim: SimModel | undefined;
  children: React.ReactNode;
}) {
  return (
    <EsimContext.Provider value={{ sim: props.sim }}>
      {props.children}
    </EsimContext.Provider>
  );
}

export function useGetEsimData() {
  const context = React.useContext(EsimContext);
  return context.sim;
}
