import useNativeBridge from 'common/routes/bridge';
import { BridgeMessageType } from 'common/routes/bridge-types';
import React from 'react';
import Joyride, { Step } from 'react-joyride';

import InstallationHighlightInstruction from './installation-highlight-instruction';

export const highlightTargetName = 'highlight-installation-target' as const;

export interface InstallationHighlighterRef {
  run: boolean;
  open(): void;
  close(): void;
}

const InstallationHighlighter = React.forwardRef<InstallationHighlighterRef>(
  (props, ref) => {
    const [run, setRun] = React.useState(false);
    const send = useNativeBridge({});

    const steps: Step[] = React.useMemo(() => {
      return [
        {
          content: (
            <InstallationHighlightInstruction
              onDone={() => {
                setRun(false);
                send?.({
                  type: BridgeMessageType.OverlayBottomNavigation,
                  data: false,
                });
              }}
            />
          ),
          target: `.${highlightTargetName}`,
          hideFooter: true,
          disableBeacon: true,
          floaterProps: {
            hideArrow: true,
          },
        },
      ];
    }, [send]);

    React.useImperativeHandle(ref, () => {
      return {
        open() {
          setRun(true);
          send?.({
            type: BridgeMessageType.OverlayBottomNavigation,
            data: true,
          });
        },
        close() {
          setRun(false);
          send?.({
            type: BridgeMessageType.OverlayBottomNavigation,
            data: false,
          });
        },
        run,
      };
    });

    return (
      <Joyride
        run={run}
        scrollOffset={64}
        scrollToFirstStep
        steps={steps}
        hideCloseButton
        disableOverlayClose
        hideBackButton
        spotlightClicks
        styles={{
          options: {
            zIndex: 10000,
          },
          tooltip: {
            padding: 16,
            borderRadius: 8,
            width: 290,
          },
          tooltipContent: {
            padding: 0,
          },
        }}
      />
    );
  },
);

export default InstallationHighlighter;
