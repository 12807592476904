import { SimModel } from 'api-hooks/sim/model';
import classNames from 'classnames';
import { SimTransferRequestStatusEnum } from 'common/constants/enum';
import { NavigationRoutes } from 'common/routes';
import Separator from 'components/common/separator';
import Button, { ListItemButton } from 'components/elements/button';
import useKurosimNavigation from 'hooks/use-kurosim-navigation';
import EsimCard from 'modules/esim/components/esim-card';
import { LabelledSection } from 'modules/main/components/section';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import structuralStyles from 'styles/layout.css';

import TransferOwnershipRequestConfirmation, {
  EsimPlansCallToAction,
} from './components/call-to-action';
import {
  ActiveEsimPlanComponent,
  PastEsimPlanComponent,
  UpcomingEsimPlanComponent,
} from './components/item-variants';
import { EsimProvider } from './hooks/use-get-esim-data';

interface Props {
  data: SimModel;
  withCard: boolean;
}

export default function EsimScreenBody(props: Props) {
  const { data, withCard } = props;
  const { t } = useTranslation();
  const { push } = useKurosimNavigation();

  return (
    <EsimProvider sim={data}>
      <div className={structuralStyles.padding({ horizontal: 16 })}>
        {withCard && (
          <div
            className={classNames(
              structuralStyles.flexbox({ direction: 'column' }),
              structuralStyles.fill({ width: true }),
              structuralStyles.padding({ bottom: 24 }),
            )}
          >
            <EsimCard
              iccid={data.iccid}
              label={data.label}
              skin={data.skin}
              status={data.status}
              Actions={<EsimCard.ActionsButton data={data} />}
            />
          </div>
        )}
        <EsimPlansCallToAction data={data} />
        {data.transferRequest &&
          data.transferRequest.status ===
            SimTransferRequestStatusEnum.Pending && (
            <>
              <Separator gap={16} direction="vertical" />
              <TransferOwnershipRequestConfirmation
                simId={data.id}
                email={data.transferRequest.user.email}
                simTransferRequestId={data.transferRequest.id}
              />
              <Separator gap={16} direction="vertical" />
            </>
          )}
      </div>
      {data.simPlans && data.simPlans.length > 0 && (
        <LabelledSection label={t('sim:active_plans')}>
          {data.simPlans.map((plan) => (
            <ListItemButton
              key={plan.id}
              onClick={() => {
                push(NavigationRoutes.EsimPlanDetail, {
                  paths: {
                    id: data.id,
                    planId: plan.id,
                  },
                });
              }}
            >
              <ActiveEsimPlanComponent data={plan} />
            </ListItemButton>
          ))}
        </LabelledSection>
      )}
      {data.pendingPlans && data.pendingPlans.length > 0 && (
        <LabelledSection label={t('sim:upcoming_plans')}>
          {data.pendingPlans.map((plan) => (
            <ListItemButton
              key={plan.id}
              onClick={() => {
                push(NavigationRoutes.EsimPlanDetail, {
                  paths: {
                    id: data.id,
                    planId: plan.id,
                  },
                });
              }}
            >
              <UpcomingEsimPlanComponent data={plan} />
            </ListItemButton>
          ))}
        </LabelledSection>
      )}
      {data.histories && data.histories.length > 0 && (
        <LabelledSection
          label={t('sim:plan_history')}
          separator={0}
          TitleEnd={
            data.hasRemainingHistories && (
              <Button
                variant={{ variant: 'tertiary', size: 'defaultLink' }}
                style={{
                  backgroundColor: 'transparent',
                }}
                mr={-8}
                onClick={() =>
                  push(NavigationRoutes.EsimHistory, {
                    locker: {
                      data,
                    },
                    paths: {
                      id: data.id,
                    },
                  })
                }
              >
                {t('common:view_extra', { extra: t('common:all') })}
              </Button>
            )
          }
        >
          {data.histories.map((plan) => (
            <ListItemButton key={plan.id} trailing={<></>}>
              <PastEsimPlanComponent data={plan} />
            </ListItemButton>
          ))}
        </LabelledSection>
      )}
    </EsimProvider>
  );
}
