import Button from 'components/elements/button';
import Text from 'components/elements/text';
import { Trans, useTranslation } from 'react-i18next';

interface InstallationHighlightInstructionProps {
  onDone: () => void;
}

export default function InstallationHighlightInstruction(
  props: InstallationHighlightInstructionProps,
) {
  const { t } = useTranslation();
  return (
    <>
      <div>
        {[...Array(3).keys()].map((index) => {
          return (
            <div
              key={index}
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'flex-start',
                gap: 8,
              }}
            >
              <div
                style={{
                  minHeight: 4,
                  minWidth: 4,
                  maxHeight: 4,
                  maxWidth: 4,
                  borderRadius: '50%',
                  backgroundColor: 'black',
                  marginTop: 6,
                }}
              />
              <Text style={{ textAlign: 'left' }} key={index}>
                <Trans
                  i18nKey={`sim:installation_sim_hightlight_0${index + 1}`}
                  components={[
                    <Text span textVariant="body1Semibold" key="1" />,
                  ]}
                />
              </Text>
            </div>
          );
        })}
      </div>
      <Button
        mt={10}
        variant={{ variant: 'secondary' }}
        fullWidth
        onClick={props.onDone}
      >
        {t('sim:hightlight_confirmation')}
      </Button>
    </>
  );
}
