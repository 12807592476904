import { Loader, RingProgress, RingProgressProps } from '@mantine/core';
import colors from 'common/styles/colors';
import React, { useImperativeHandle } from 'react';
import structuralStyles from 'styles/layout.css';

interface DefaultPullRefreshLoadingIndicatorProps {
  loaderSize?: number;
}

export function DefaultPullRefreshLoadingIndicator(
  props: DefaultPullRefreshLoadingIndicatorProps,
) {
  return (
    <div
      className={structuralStyles.flexbox({
        justify: 'center',
      })}
    >
      <Loader size={props?.loaderSize ?? 24} />
    </div>
  );
}

export interface PullRefreshProgressIndicatorRemote {
  /** This is used so that the indicator controls the percentage state (thus reducing rerenders in parent). */
  setPercentage(percentage: number): void;
}
export interface IPullRefreshActingIndicatorProps {
  remote: React.MutableRefObject<PullRefreshProgressIndicatorRemote | null>;
}
interface DefaultPullRefreshProgressIndicatorProps
  extends IPullRefreshActingIndicatorProps {
  size?: number;
  progressProps?: RingProgressProps;
}

export function DefaultPullRefreshProgressIndicator(
  props: DefaultPullRefreshProgressIndicatorProps,
) {
  const [percentage, setPercentage] = React.useState(0);
  useImperativeHandle(
    props.remote,
    () =>
      ({
        setPercentage(pct) {
          setPercentage(Math.max(0, Math.min(1, pct)));
        },
      }) satisfies PullRefreshProgressIndicatorRemote,
  );
  const size = props.size ?? 30;
  const innerSize = Math.max(Math.min(size, size * percentage), 4);
  return (
    <div
      className={structuralStyles.flexbox({
        justify: 'center',
      })}
    >
      <RingProgress
        thickness={3}
        {...props.progressProps}
        size={innerSize}
        sections={[
          {
            value: Math.max(0, Math.min(100, percentage * 100)),
            color: colors.foregroundPrimary,
          },
        ]}
      />
    </div>
  );
}
